import * as React from "react";

import { Typography, Wave } from "@components/atoms";
import { Container, SVG } from "./Card.styles";

export interface CardProps {
  children: React.ReactChild | React.ReactChild[];
  title?: string;
  sticky?: boolean;
}

const Card: React.FC<CardProps> = ({ children, title, sticky }) => {
  return (
    <Container sticky={sticky}>
      {title && (
        <>
          <Typography variant="h4" align="center">
            {title}
          </Typography>
          <SVG width="35" height="6" xmlns="http://www.w3.org/2000/svg">
            <Wave />
          </SVG>
        </>
      )}
      {children}
    </Container>
  );
};

export default Card;
