import styled from "styled-components";

type ContainerProps = {
  sticky?: boolean;
};

export const Container = styled.div<ContainerProps>`
  background: ${(props) => props.theme.pallette.background.paper};
  border-radius: ${(props) => props.theme.utils.borderRadius};
  box-shadow: ${(props) => props.theme.utils.boxShadowCard};
  padding: 32px 28px;
  ${(props) => (props.sticky ? "display: none;" : "")}

  @media (min-width: 1024px) {
    ${(props) =>
      props.sticky ? "position: sticky; top: 148px; display: block;" : ""}
  }
`;

export const SVG = styled.svg`
  margin: 8px auto;
  margin-bottom: 24px;
  display: block;
`;
