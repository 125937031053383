import { useStaticQuery, graphql } from "gatsby";

type Post = {
  fields: {
    slug: string;
  };
  id: string;
  frontmatter: {
    date: string;
    title: string;
    thumbnail: {
      childImageSharp: {
        resize: {
          src: string;
        };
      };
    };
  };
};

type PopularPosts = {
  allMdx: {
    nodes: Post[];
  };
};

const usePopularPosts = (): PopularPosts =>
  useStaticQuery(graphql`
    {
      allMdx(filter: { frontmatter: { trending: { eq: true } } }) {
        nodes {
          fields {
            slug
          }
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            thumbnail {
              childImageSharp {
                resize(width: 128) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `);

export default usePopularPosts;
