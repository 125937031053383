import * as React from "react";

import Card from "../Card";
import { Typography } from "@components/atoms";
import Logo from "@components/Logo";
import Socials from "@components/Socials";

import info from "@constants/branding";

import { Container } from "./Map.styles";
//@ts-ignore
import mapBackground from "./map.png";

export interface MapProps {}

const Map: React.FC<MapProps> = () => {
  return (
    <Card>
      <Container style={{ backgroundImage: `url(${mapBackground})` }}>
        <Logo />
        <Typography variant="small" align="center">
          {info.description}
        </Typography>
        <Socials />
      </Container>
    </Card>
  );
};

export default Map;
