import * as React from "react";

import Substack from "@components/Substack";
import { DisplayAd } from "@components/Ads/Google";
import PopularPosts from "./PopularPosts/PopularPosts";
import Topics from "./Topics";
import Map from "./Map";
import TableOfContents from "./TableOfContents";

import {
  Container,
  ContentLeft,
  ContentRight,
  ContentRightSticky,
  CenterCard,
} from "./Widgets.styles";

export interface WidgetsProps {
  children: React.ReactChild | React.ReactChild[];
  postId?: string;
  tableOfContents?: any[];
}

const Widgets: React.FC<WidgetsProps> = ({
  children,
  postId,
  tableOfContents,
}) => {
  return (
    <Container>
      <ContentLeft>{children}</ContentLeft>
      <ContentRight>
        <Map />
        <PopularPosts postId={postId} />
        <Topics />
        <CenterCard>
          <DisplayAd slot="8111351746" />
        </CenterCard>
        <ContentRightSticky>
          <TableOfContents content={tableOfContents} />
        </ContentRightSticky>
      </ContentRight>
    </Container>
  );
};

export default Widgets;
