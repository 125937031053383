import * as React from "react";
import kebabCase from "lodash.kebabcase";
import { Icon } from "@components/atoms";
import Card from "../Card";

import useTopics from "./Topics.hooks";

import { Container, Item, Link } from "./Topics.styles";

type Topic = {
  topic: string;
  totalCount: number;
};

type Data = {
  allMdx: {
    group: Topic[];
  };
};

export interface TopicsProps {}

const Topics: React.FC<TopicsProps> = () => {
  const data: Data = useTopics();

  return (
    <Card title="Explore Topics">
      <Container>
        {data.allMdx.group.map(({ topic, totalCount }) => (
          <Item key={topic}>
            <Link to={`/category/${kebabCase(topic)}`}>
              <Icon size={12} name="chevron-right" />
              {topic}
            </Link>
            <span>{`(${totalCount})`}</span>
          </Item>
        ))}
      </Container>
    </Card>
  );
};

export default Topics;
