import * as React from "react";
import { Link } from "gatsby";
import cn from "classnames";
import { useMediaQuery } from "react-responsive";

import Card from "../Card";

import { Container } from "./TableOfContents.styles";

interface TableOfContentsProps {
  content?: any[];
}

const { useState, useEffect } = React;

const TableOfContents: React.FunctionComponent<TableOfContentsProps> = ({
  content,
}) => {
  const [activeLink, setActiveLink] = useState("Introduction");

  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  const scrollToElement = (event: React.MouseEvent<HTMLLIElement>) => {
    const { id } = event.currentTarget.dataset;
    if (id) {
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio === 1) {
            setActiveLink(entry.target.id);
          }
        });
      },
      {
        root: null,
        threshold: 1.0,
      }
    );

    document
      .querySelectorAll("h3[id]")
      .forEach((item) => observer.observe(item));

    return () => {
      document
        .querySelectorAll("h3[id]")
        .forEach((item) => observer.unobserve(item));
    };
  }, []);

  if (!content || !isDesktop) return null;

  return (
    <Card title="Quick Links">
      <Container id="quick-links">
        <li
          id="quick-links-intro"
          key="intro"
          onClick={scrollToTop}
          className={cn({ active: activeLink === "Introduction" })}
        >
          Introduction
        </li>
        {content.map((item) => (
          <li
            onClick={scrollToElement}
            key={item.url}
            data-id={item.url}
            className={cn({ active: activeLink === item.url })}
          >
            {item.title}
          </li>
        ))}
      </Container>
    </Card>
  );
};

export default TableOfContents;
