import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const CenterCard = styled.div`
  display: grid;
  place-items: center;
`;

export const ContentLeft = styled.div`
  @media (min-width: 1024px) {
    margin-right: 40px;
    flex: 1 1 auto;
    max-width: 726px;
  }
`;

export const ContentRight = styled.div`
  margin-top: 24px;

  @media (min-width: 1024px) {
    min-width: 350px;
    margin-top: 56px;
    flex: 0 1;
  }

  & > *:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const ContentRightSticky = styled.div`
  overflow: visible;

  @media (min-width: 1024px) {
    position: sticky;
    top: 148px;
  }

  & > *:not(:last-child) {
    margin-bottom: 40px;
  }
`;
