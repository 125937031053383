import * as React from "react";

import Card from "../Card";

import usePopularPosts from "./PopularPosts.hooks";

import {
  Container,
  Item,
  Image,
  Badge,
  Description,
  AdUnit,
} from "./PopularPosts.styles";

export interface PopularPostsProps {
  postId?: string;
}

const PopularPosts: React.FC<PopularPostsProps> = ({ postId }) => {
  const data = usePopularPosts();

  return (
    <Card title="Popular Posts">
      <Container>
        {data.allMdx.nodes
          .filter((node) => node.id !== postId)
          .map((post, index) => (
            <li key={post.frontmatter.title}>
              <Item to={post.fields.slug}>
                <Image
                  style={{
                    backgroundImage: `url(${post.frontmatter.thumbnail.childImageSharp.resize.src})`,
                  }}
                >
                  <Badge>{index + 1}</Badge>
                </Image>
                <Description>
                  <h6>{post.frontmatter.title}</h6>
                  <p>{post.frontmatter.date}</p>
                </Description>
              </Item>
            </li>
          ))}
        {/* <li key="google-ad">
          <AdUnit
            data-ad-client="ca-pub-8412028141816459"
            data-ad-slot="7504839946"
          ></AdUnit>
        </li> */}
      </Container>
    </Card>
  );
};

export default PopularPosts;
