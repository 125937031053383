import styled from "styled-components";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";

export const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 12px 0;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${(props) => props.theme.pallette.background.divider};
  }

  span {
    color: ${(props) => props.theme.pallette.text.default};
    font-weight: 300;
    font-size: 14px;
  }
`;

export const Link = styled(GatsbyLink)<GatsbyLinkProps<{}>>`
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.theme.pallette.text.default};
  will-change: color;
  transition: color 300ms;
  text-decoration: none;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  &:hover {
    color: ${(props) => props.theme.pallette.primary.main};
  }

  svg {
    margin-right: 16px;
    fill: ${(props) => props.theme.pallette.primary.main};
  }
`;
