import styled from "styled-components";

export const Container = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  padding-left: 24px;

  li {
    font-weight: 500;
    font-size: 14px;
    color: ${(props) => props.theme.pallette.text.default};
    cursor: pointer;
    padding: 2px;
    will-change: color;
    transition: color 0.3s;

    &::marker {
      color: ${(props) => props.theme.pallette.primary.main};
    }

    &.active {
      color: ${(props) => props.theme.pallette.primary.main};
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }
`;
