import styled from "styled-components";
import { Link, GatsbyLinkProps } from "gatsby";

export const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const AdUnit = styled.ins`
  display: inline-block;
  width: 294px;
  height: 92px;
`;

export const Item = styled(Link)<GatsbyLinkProps<{}>>`
  display: flex;
  align-items: center;
  padding: 16px 0;
  position: relative;
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.pallette.background.divider};
  }
`;

export const Image = styled.div`
  min-width: 60px;
  width: 60px;
  height: 60px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin-right: 24px;
  position: relative;
`;

export const Badge = styled.div`
  position: absolute;
  top: -6px;
  left: -4px;
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.pallette.secondary.main};
  color: #fff;
  box-shadow: ${(props) => props.theme.utils.boxShadowLight};
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
`;

export const Description = styled.div`
  h6 {
    color: ${(props) => props.theme.pallette.text.default};
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    will-change: color;
    transition: color 300ms;

    &:hover {
      color: ${(props) => props.theme.pallette.primary.main};
    }
  }

  p {
    color: ${(props) => props.theme.pallette.text.default};
    margin: 0;
    font-size: 12px;
    font-weight: 300;
  }
`;
