import * as React from "react";

import Ad from "./Ad";

interface AffiliateProps {}

const { useState, useEffect } = React;

const Affiliate: React.FunctionComponent<AffiliateProps> = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(() => true);

    () => {
      document.querySelectorAll("ins").forEach((item) => item.remove());
    };
  }, []);

  if (!mounted) return null;

  const article = document.querySelector("article");

  if (article) {
    return (
      <>
        <Ad index={1} article={article} />
        <Ad index={2} article={article} />
        <Ad index={3} article={article} />
        <Ad index={4} article={article} />
      </>
    );
  }

  return null;
};

export default Affiliate;
