import * as React from "react";
import { useLocation } from "@reach/router";

// @ts-ignore
import info from "@constants/branding";

interface DisplayAdProps {
  slot: string;
}

const { useEffect } = React;

const DisplayAd: React.FunctionComponent<DisplayAdProps> = ({ slot }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    //@ts-ignore
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, [pathname]);

  return (
    <ins
      className="adsbygoogle"
      data-ad-client={info.publisherId}
      data-ad-slot={slot}
      style={{
        display: "inline-block",
        width: 275,
        height: 350,
      }}
    />
  );
};

export default DisplayAd;
