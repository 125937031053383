import * as React from "react";
import ReactDOM from "react-dom";
import { useLocation } from "@reach/router";

// @ts-ignore
import info from "@constants/branding";

interface AffiliateProps {
  article: HTMLElement;
  index: number;
}

const ads = [
  <ins
    key="article-1"
    className="adsbygoogle"
    style={{ display: "block", textAlign: "center" }}
    data-ad-layout="in-article"
    data-ad-format="fluid"
    data-ad-client={info.publisherId}
    data-ad-slot="1876018403"
  />,
  <ins
    key="display-1"
    className="adsbygoogle"
    style={{ display: "block" }}
    data-ad-client={info.publisherId}
    data-ad-slot="6903934050"
    data-ad-format="auto"
    data-full-width-responsive="true"
  />,
  <ins
    key="article-2"
    className="adsbygoogle"
    style={{ display: "block", textAlign: "center" }}
    data-ad-layout="in-article"
    data-ad-format="fluid"
    data-ad-client={info.publisherId}
    data-ad-slot="1702959712"
  />,
  <ins
    key="display-2"
    className="adsbygoogle"
    style={{ display: "block" }}
    data-ad-client={info.publisherId}
    data-ad-slot="1268463998"
    data-ad-format="auto"
    data-full-width-responsive="true"
  />,
  <ins
    key="article-3"
    className="adsbygoogle"
    style={{ display: "block", textAlign: "center" }}
    data-ad-layout="in-article"
    data-ad-format="fluid"
    data-ad-client={info.publisherId}
    data-ad-slot="4938393493"
  />,
];

const { useEffect } = React;

const Medium: React.FunctionComponent<AffiliateProps> = ({
  article,
  index,
}) => {
  const { pathname } = useLocation();

  const heading = document.querySelectorAll("h3[id]").item(index);

  useEffect(() => {
    if (heading) {
      //@ts-ignore
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, [pathname]);

  if (!heading) return null;

  return ReactDOM.createPortal(
    ads[index],
    article.insertBefore(document.createElement("p"), heading)
  );
};

export default Medium;
