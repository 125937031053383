import { useStaticQuery, graphql } from "gatsby";

const useTopics = () =>
  useStaticQuery(graphql`
    {
      allMdx {
        group(field: frontmatter___category) {
          topic: fieldValue
          totalCount
        }
      }
    }
  `);

export default useTopics;
