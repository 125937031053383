import styled from "styled-components";

export const Container = styled.div`
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;
